import { Heading } from '@chakra-ui/react';

export default function HeadingComponent({ children, ...props }) {
    return (
        <Heading
            as="h1"
            size="2xl"
            color="black"
            fontWeight="extrabold"
            letterSpacing="tight"
            mt="6"
            {...props}
        >
            {children}
        </Heading>
    );
}
