import * as React from 'react';

function SvgGooglePlayBadge(props) {
    return (
        <svg viewBox="0 0 135 40" {...props}>
            <defs>
                <linearGradient
                    id="google-play-badge_svg__a"
                    x1={21.8}
                    y1={191.85}
                    x2={5.02}
                    y2={175.07}
                    gradientTransform="matrix(1 0 0 -1 0 200.56)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#00a0ff" />
                    <stop offset={0.01} stopColor="#00a1ff" />
                    <stop offset={0.26} stopColor="#00beff" />
                    <stop offset={0.51} stopColor="#00d2ff" />
                    <stop offset={0.76} stopColor="#00dfff" />
                    <stop offset={1} stopColor="#00e3ff" />
                </linearGradient>
                <linearGradient
                    id="google-play-badge_svg__b"
                    x1={33.83}
                    y1={180.56}
                    x2={9.64}
                    y2={180.56}
                    gradientTransform="matrix(1 0 0 -1 0 200.56)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#ffe000" />
                    <stop offset={0.41} stopColor="#ffbd00" />
                    <stop offset={0.78} stopColor="orange" />
                    <stop offset={1} stopColor="#ff9c00" />
                </linearGradient>
                <linearGradient
                    id="google-play-badge_svg__c"
                    x1={24.83}
                    y1={178.27}
                    x2={2.07}
                    y2={155.51}
                    gradientTransform="matrix(1 0 0 -1 0 200.56)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#ff3a44" />
                    <stop offset={1} stopColor="#c31162" />
                </linearGradient>
                <linearGradient
                    id="google-play-badge_svg__d"
                    x1={7.3}
                    y1={200.39}
                    x2={17.46}
                    y2={190.23}
                    gradientTransform="matrix(1 0 0 -1 0 200.56)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#32a071" />
                    <stop offset={0.07} stopColor="#2da771" />
                    <stop offset={0.48} stopColor="#15cf74" />
                    <stop offset={0.8} stopColor="#06e775" />
                    <stop offset={1} stopColor="#00f076" />
                </linearGradient>
            </defs>
            <g data-name="Ebene 2">
                <rect width={135} height={40} rx={5} />
                <path
                    d="M130 .8a4.2 4.2 0 014.2 4.2v30a4.2 4.2 0 01-4.2 4.2H5A4.2 4.2 0 01.8 35V5A4.2 4.2 0 015 .8h125m0-.8H5a5 5 0 00-5 5v30a5 5 0 005 5h125a5 5 0 005-5V5a5 5 0 00-5-5z"
                    fill="#a6a6a6"
                />
                <path
                    d="M68.14 21.75A4.26 4.26 0 1072.41 26a4.19 4.19 0 00-4.27-4.25zm0 6.83a2.58 2.58 0 112.4-2.58 2.46 2.46 0 01-2.4 2.58zm-9.32-6.83A4.26 4.26 0 1063.09 26a4.19 4.19 0 00-4.27-4.25zm0 6.83a2.58 2.58 0 112.4-2.58 2.46 2.46 0 01-2.4 2.58zm-11.08-5.52v1.8h4.32a3.77 3.77 0 01-1 2.27 4.42 4.42 0 01-3.34 1.32 4.8 4.8 0 010-9.6A4.64 4.64 0 0151 20.14l1.27-1.27a6.3 6.3 0 00-4.53-1.82 6.61 6.61 0 100 13.21 6.07 6.07 0 004.61-1.85 6 6 0 001.56-4.23 6.27 6.27 0 00-.09-1.12zm45.31 1.4a4 4 0 00-3.64-2.71 4 4 0 00-4 4.25 4.23 4.23 0 007.76 2.37l-1.45-1a2.41 2.41 0 01-2.09 1.17 2.15 2.15 0 01-2.06-1.29l5.69-2.35zm-5.8 1.42a2.33 2.33 0 012.23-2.48 1.64 1.64 0 011.57.9zM82.63 30h1.87V17.5h-1.87zm-3.06-7.3h-.07a2.94 2.94 0 00-2.24-.95 4.26 4.26 0 000 8.51 2.88 2.88 0 002.24-1h.07v.61c0 1.63-.87 2.5-2.27 2.5a2.36 2.36 0 01-2.15-1.51l-1.62.67a4 4 0 003.77 2.52c2.19 0 4-1.29 4-4.43V22h-1.73zm-2.15 5.88a2.58 2.58 0 010-5.15A2.39 2.39 0 0179.7 26a2.38 2.38 0 01-2.28 2.58zm24.39-11.08h-4.48V30h1.87v-4.74h2.61a3.89 3.89 0 100-7.76zm0 6H99.2v-4.26h2.65a2.14 2.14 0 110 4.28zm11.54-1.79a3.49 3.49 0 00-3.33 1.91l1.65.69a1.78 1.78 0 011.71-.91 1.8 1.8 0 012 1.61v.12a4.18 4.18 0 00-1.95-.48c-1.78 0-3.6 1-3.6 2.82a2.89 2.89 0 003.11 2.75 2.65 2.65 0 002.38-1.22h.06v1h1.8v-4.81c0-2.19-1.66-3.46-3.79-3.46zm-.23 6.85c-.61 0-1.46-.31-1.46-1.06 0-1 1.06-1.34 2-1.34a3.32 3.32 0 011.7.42 2.26 2.26 0 01-2.2 2zM123.74 22l-2.14 5.42h-.06L119.32 22h-2l3.33 7.58-1.9 4.21h2L125.82 22zm-16.8 8h1.86V17.5h-1.86z"
                    fill="#fff"
                />
                <path
                    d="M10.44 7.54a2 2 0 00-.44 1.4v22.12a1.94 1.94 0 00.47 1.4l.07.08L22.9 20.15v-.3L10.51 7.47z"
                    fill="url(#google-play-badge_svg__a)"
                />
                <path
                    d="M27 24.28l-4.1-4.13v-.3l4.1-4.13.09.06L32 18.56c1.4.79 1.4 2.09 0 2.89l-4.89 2.78z"
                    fill="url(#google-play-badge_svg__b)"
                />
                <path
                    d="M27.12 24.23L22.9 20 10.44 32.46a1.62 1.62 0 002.07.07l14.61-8.3"
                    fill="url(#google-play-badge_svg__c)"
                />
                <path
                    d="M27.12 15.78l-14.61-8.3a1.61 1.61 0 00-2.07.06L22.9 20z"
                    fill="url(#google-play-badge_svg__d)"
                />
                <path
                    d="M27 24.13l-14.49 8.25a1.66 1.66 0 01-2 0l-.07.07.07.08a1.66 1.66 0 002 0l14.61-8.3z"
                    style={{
                        isolation: 'isolate',
                    }}
                    opacity={0.2}
                />
                <path
                    d="M10.44 32.32a2 2 0 01-.44-1.41v.15a1.94 1.94 0 00.47 1.4l.07-.07zM32 21.3l-5 2.83.09.1L32 21.44A1.75 1.75 0 0033.06 20 1.86 1.86 0 0132 21.3z"
                    style={{
                        isolation: 'isolate',
                    }}
                    opacity={0.12}
                />
                <path
                    d="M12.51 7.62L32 18.7a1.86 1.86 0 011.06 1.3A1.75 1.75 0 0032 18.56L12.51 7.48C11.12 6.68 10 7.34 10 8.94v.15c0-1.6 1.12-2.26 2.51-1.47z"
                    style={{
                        isolation: 'isolate',
                    }}
                    opacity={0.25}
                    fill="#fff"
                />
                <path
                    d="M43 13.13a1.72 1.72 0 01-1.11-.36 2 2 0 01-.66-1l.71-.29a1.1 1.1 0 001.09 1 1 1 0 00.83-.35 1.45 1.45 0 00.29-.94V7h.77v4.09a2 2 0 01-.54 1.51 1.86 1.86 0 01-1.38.53zm6.71-5.39H47v1.9h2.47v.72H47v1.9h2.74V13H46.2V7h3.51zM53 13h-.77V7.74h-1.72V7h4.12v.74H53zm2.27 0v-.8l3.19-4.46H55.4V7h4v.8l-3.19 4.46h3.22V13zm7.27 0h-.77V7.74H60.1V7h4.12v.74h-1.68zm4.66 0V7h2.17a1.8 1.8 0 011.22.46 1.52 1.52 0 01.52 1.17 1.31 1.31 0 01-.22.76 1.41 1.41 0 01-.59.49 1.57 1.57 0 01.73.52 1.4 1.4 0 01.28.85 1.57 1.57 0 01-.54 1.22 1.83 1.83 0 01-1.28.49zm.8-3.43h1.4a.87.87 0 00.7-.29.9.9 0 00.27-.63.93.93 0 00-.29-.65.85.85 0 00-.67-.28H68zm0 2.69h1.56a.92.92 0 00.72-.31 1 1 0 00.27-.68 1 1 0 00-.28-.67 1 1 0 00-.75-.31H68zm7.85-4.52h-2.73v1.9h2.46v.72h-2.46v1.9h2.73V13h-3.5V7h3.5zM77.06 13V7h.77v6z"
                    stroke="#fff"
                    strokeMiterlimit={10}
                    strokeWidth={0.2}
                    fill="#fff"
                />
            </g>
        </svg>
    );
}

export default SvgGooglePlayBadge;
