import gql from 'graphql-tag';

const GET_USER = gql`
    query GetUser($id: String!) {
        users_by_pk(id: $id) {
            id
            username
            first_name
            last_name
            phone
            picture_url
            role
            gender
            birthdate
            email
            is_newsletter_enabled
        }
    }
`;

const GET_USER_COMPANY_BYID = gql`
    query GetUserCompanyById($id: String!) {
        users_company_view(where: { id: { _eq: $id } }) {
            id
            first_name
            last_name
        }
    }
`;

module.exports = {
    GET_USER,
    GET_USER_COMPANY_BYID,
};
