import React from 'react';
import { useRouter } from 'next/router';
import ActiveLink from '@/components/ActiveLink';
import { useAuthContext } from '@/components/context/authContext';
import UserPicture from '@/components/ui/user/UserPicture';
import { Stack, Button, Spinner, useToast, Text, Box } from '@chakra-ui/react';
import Icon from '@mdi/react';
import {
    mdiArrowTopRightThick,
    mdiAccountPlus,
    mdiLogoutVariant,
    mdiMagnify,
} from '@mdi/js';
import { toastSuccess, toastError } from '@/components/helper';
import useUser from '../context/useUser';
import { useApolloClient } from '@apollo/client';

function authenticatedMenu(auth, user, logout, router, toast, apolloClient) {

    return (
        <Stack
            spacing={3}
            justifyContent="flex-end"
            alignItems="center"
            direction={['column', 'row', 'row', 'row']}
        >
            {/* <ActiveLink
                href="/suche"
                activeClassName="active"
                includePath={false}
            >
                <Button
                    width={['full', 'full', 'initial']}
                    leftIcon={<Icon path={mdiMagnify} size={0.9} />}
                    bg="gray.500"
                    variant="solid"
                    as="a"
                    cursor="pointer"
                    className="btn-header"
                    justifyContent="left"
                >
                    Suche
                </Button>
            </ActiveLink> */}

            {/* <ActiveLink
                href="/einstellungen"
                activeClassName="active"
                includePath={false}
            >
                <Button
                    width={['full', 'full', 'initial']}
                    bg="gray.500"
                    variant="solid"
                    as="a"
                    cursor="pointer"
                    className="btn-header"
                    justifyContent="left"
                >
                    Einstellungen
                </Button>
            </ActiveLink> */}

            <ActiveLink
                href="/dashboard"
                activeClassName="active"
                includePath={true}
            >
                <a className="btn-header">Dashboard</a>
            </ActiveLink>

            <ActiveLink
                href="/suche"
                activeClassName="active"
                includePath={false}
            >
                <a className="btn-header">Suche</a>
            </ActiveLink>

            <ActiveLink
                href="/einstellungen"
                activeClass="navbar-userpicture-active"
                activeClassName="navbar-userpicture-active"
                includePath={false}
            >
                <a>
                    <Box
                        as="span"
                        // cursor="pointer"
                        // px="3"
                        // py="2"
                        display={['none', 'none', 'block']}
                        // _hover={{ boxShadow: 'md' }}
                        // py="1"
                        height="48px"
                    >
                        {/* asda */}
                        <UserPicture
                            user={user}
                            width="48"
                            height="48"
                            cssClasses="navbar-userpicture-hover"
                        />
                    </Box>
                </a>
            </ActiveLink>

            <ActiveLink
                href="/einstellungen"
                activeClassName=""
                includePath={false}
            >
                <Box
                    as="a"
                    className="btn-header"
                    // cursor="pointer"
                    // px="3"
                    // py="2"
                    display={['block', 'block', 'none']}
                    // _hover={{ boxShadow: 'md' }}
                    // pt="2"
                >
                    Einstellungen
                </Box>
                {/* <a className="btn-header">Einstellungen</a> */}
            </ActiveLink>

            <Button
                px="3"
                py="2"
                width={['full', 'full', 'initial']}
                bg="brand.blue"
                variant="solid"
                as="a"
                cursor="pointer"
                className="btn-header"
                justifyContent={['left', 'center']}
                onClick={() => {
                    try {
                        logout();
                        apolloClient.resetStore();
                        // router.push('/')
                        toastSuccess(toast, 'Logout erfolgreich');
                    } catch (error) {
                        toastError(toast, error);
                    }
                }}
            >
                <Box display={['none', 'none', 'block']}>
                    <Icon path={mdiLogoutVariant} size={0.9} />
                </Box>
                <Text mt="0" display={['block', 'block', 'none']}>
                    Abmelden
                </Text>
            </Button>
        </Stack>
    );
}

const MenuRight = props => {
    // prepare

    const apolloClient = useApolloClient();
    const router = useRouter();
    const toast = useToast();
    let [user, isLoading, refresh] = useUser();
    let { isLoadingAuth, auth, logout, isAuthenticated } = useAuthContext();

    if (isLoadingAuth === true || isLoading) {
        return (
            <Box textAlign="right" mr="3" mt="2">
                <Spinner />
            </Box>
        );
    }

    // // is user authenticated
    if (isAuthenticated()) {
        return authenticatedMenu(auth, user, logout, router, toast, apolloClient);
    }

    return (
        <Stack
            spacing={3}
            justifyContent="flex-end"
            direction={['column', 'row', 'row', 'row']}
        >
            <ActiveLink
                href="/registrieren"
                activeClassName="active"
                includePath={false}
            >
                <Button
                    width={['full', 'full', 'initial']}
                    leftIcon={<Icon path={mdiAccountPlus} size={1} />}
                    bg="gray.500"
                    variant="solid"
                    as="a"
                    cursor="pointer"
                    className="btn-header"
                >
                    Registrieren
                </Button>
            </ActiveLink>

            <ActiveLink
                href="/login"
                activeClassName="active"
                includePath={false}
            >
                <Button
                    width={['full', 'full', 'initial']}
                    leftIcon={<Icon path={mdiArrowTopRightThick} size={1} />}
                    bg="gray.500"
                    variant="solid"
                    as="a"
                    cursor="pointer"
                    className="btn-header"
                >
                    Login
                </Button>
            </ActiveLink>
        </Stack>
    );
};

export default MenuRight;
