import NextLink from 'next/link';
import {
    Box,
    Container,
    Link,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
    Flex,
} from '@chakra-ui/react';
import LogoDark from '../images/LogoDark';
import LogoTextDark from '../images/LogoTextDark';
import GooglePlayStore from '@/components/images/GooglePlayStore';
import IosAppStore from '@/components/images/IosAppStore';
import Icon from '@mdi/react';
import { mdiPhone, mdiEmail, mdiEmailFastOutline } from '@mdi/js';
import SvgWhatsApp from '../images/WhatsApp';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export default function LargeWithNewsletter() {
    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
        >
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', lg: '2fr 1fr 1fr 2fr' }}
                    spacing={8}
                >
                    <Stack spacing={6}>
                        <Flex align="center" mr={6} mt="3">
                            <LogoDark />
                            <LogoTextDark />
                        </Flex>
                        <Text fontSize={'sm'}>
                            &copy; 2020 TakeActive
                            <br />
                            <br />
                            Alcodo UG (haftungsbeschränkt)
                            <br />
                            Ramdohrstr. 41
                            <br />
                            28205 Bremen
                        </Text>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>TakeActive</ListHeader>
                        <Link href={'/'}>Startseite</Link>
                        <Link href={'/anbieter'}>Für Anbieter</Link>
                        <Link href={'/kontakt'}>Kontakt</Link>
                        <Link href={'/agb'}>AGB</Link>
                        <Link href={'/impressum'}>Impressum</Link>
                        {/* <Link href={'#'}>Contact us</Link>
                        <Link href={'#'}>Pricing</Link>
                        <Link href={'#'}>Testimonials</Link> */}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Kontakt</ListHeader>
                        <Flex
                            justify="center"
                            mt="8"
                            as="a"
                            href="tel:+4942168563620"
                            whiteSpace="nowrap"
                        >
                            <Icon path={mdiPhone} size={0.9} />
                            <Text mt="0" ml="3">
                                +49 (0)421 / 685 63 620
                            </Text>
                        </Flex>

                        <Flex
                            justify="center"
                            mt="4"
                            as="a"
                            href="mailto:info@takeactive.com"
                            target="_blank"
                        >
                            <Icon path={mdiEmail} size={0.9} />
                            <Text mt="0" ml="3">
                                info@takeactive.com
                            </Text>
                        </Flex>

                        <NextLink href="/kontakt" passHref>
                            <Flex as="a" justify="center" mt="4">
                                <Icon path={mdiEmailFastOutline} size={0.9} />
                                <Text mt="0" ml="3">
                                    Kontaktformular
                                </Text>
                            </Flex>
                        </NextLink>

                        <Link
                            href="https://wa.me/+4942168563620?text=Hallo%20TakeActive%2C%20ich%20habe%20da%20mal%20eine%20Frage"
                            target="_blank"
                            title="WhatsApp"
                            _hover={{
                                opacity: 0.5,
                            }}
                            pl={7}
                            style={{ width: '13rem' }}
                        >
                            <SvgWhatsApp />
                        </Link>
                        {/* <Link href={'#'}>Help Center</Link>
                        <Link href={'#'}>Terms of Service</Link>
                        <Link href={'#'}>Legal</Link>
                        <Link href={'#'}>Privacy Policy</Link>
                        <Link href={'#'}>Satus</Link> */}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>App installieren</ListHeader>
                        {/* <Stack direction={'row'}> */}
                        <Flex
                            // mt={[4, 4, 8]}
                            flexDirection="column"
                            justifyContent={['center', 'center', 'left']}
                        >
                            <Link
                                href="https://apps.apple.com/us/app/id1523147697"
                                target="_blank"
                                title="App Store"
                                _hover={{
                                    opacity: 0.5,
                                }}
                                // ml={3}
                                // w="48"
                                // w="32"
                                style={{ width: '13rem' }}
                            >
                                <IosAppStore />
                            </Link>
                            <Link
                                href="https://play.google.com/store/apps/details?id=com.takeactive"
                                target="_blank"
                                title="Google Play"
                                _hover={{
                                    opacity: 0.5,
                                }}
                                // ml={3}
                                // w="32"
                                mt="2"
                                style={{ width: '13rem' }}
                            >
                                <GooglePlayStore />
                            </Link>
                        </Flex>
                        {/* </Stack> */}
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}
