import React from 'react';
import Image from 'next/future/image';
import { getStorageUrlPublic } from '@/components/helper';

function getLocalDefaultImage(user) {
    let src = '/assets/images/member/nophoto_male.png';

    if (user?.gender === 'female') {
        src = '/assets/images/member/nophoto_female.png';
    }

    return src;
}

const UserPicture = ({ user, width = 48, height = 48, cssClasses }) => {
    let src;

    if (user?.picture_url) {
        src = getStorageUrlPublic(user.picture_url);
    } else {
        src = getLocalDefaultImage(user);
    }

    return (
        <Image
            data-id={user?.id}
            src={src}
            alt={user?.username ?? 'userimage'}
            width={width}
            height={height}
            className={'rounded-full ' + cssClasses}
        />
    );
};

export default UserPicture;
