import { useQuery } from '@apollo/client';
import { GET_USER } from '@/components/graphql/user';
import { useAuthContext } from './authContext';

export default function useUser() {
    let { isAuthenticated, auth } = useAuthContext();

    // console.log('useUser authdata:', auth)
    const { loading, error, data, refetch } = useQuery(GET_USER, {
        variables: {
            id: auth?.uid,
        },
        skip: !isAuthenticated(),
    });

    let user = data ? data?.users_by_pk : undefined;

    return [user, loading, refetch, error];
}
