import React from 'react';
import ActiveLink from '@/components/ActiveLink';
import Logo from '../images/Logo';
import LogoText from '../images/LogoText';
import { Container, Box, Flex } from '@chakra-ui/react';
import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';

const Header = props => {
    const [show, setShow] = React.useState(false);
    const handleToggle = () => setShow(!show);

    return (
        <Box bg="brand.blue">
            <Container maxW="7xl">
                <Flex
                    as="nav"
                    justify="space-between"
                    align="center"
                    wrap="wrap"
                    px={1}
                    pb="3"
                    color={['white', 'white', 'primary.700', 'primary.700']}
                    {...props}
                >
                    {/* logo */}
                    <ActiveLink href="/">
                        <a>
                            <Flex align="center" mr={6} mt="3">
                                <Logo />
                                <LogoText />
                            </Flex>
                        </a>
                    </ActiveLink>

                    {/* burger button */}
                    <Box
                        mt="3"
                        display={{ base: 'block', md: 'none' }}
                        onClick={handleToggle}
                        className={`btn-burger ${show ? 'active' : ''}`}
                    >
                        <span className="btn-burger__line-1"></span>
                        <span className="btn-burger__line-2"></span>
                        <span className="btn-burger__line-3"></span>
                    </Box>

                    {/* menu left */}
                    <Box
                        mt="3"
                        flexGrow="1"
                        display={{
                            base: show ? 'block' : 'none',
                            md: 'block',
                        }}
                        flexBasis={{ base: '100%', md: 'auto' }}
                    >
                        <MenuLeft />
                    </Box>

                    {/* menu right */}
                    <Box
                        mt="3"
                        flexGrow="1"
                        display={{
                            base: show ? 'block' : 'none',
                            md: 'block',
                        }}
                        flexBasis={{ base: '100%', md: 'auto' }}
                    >
                        <MenuRight />
                    </Box>
                </Flex>
            </Container>
        </Box>
    );
};

export default Header;
