import { useAuthContext } from '@/components/context/authContext';
import Bread from '@/components/ui/Bread';
import Consent from '@/components/ui/Consent';
import Container from '@/components/ui/Container';
import Footer from '@/components/ui/Footer';
import Header from '@/components/ui/header';
import Heading from '@/components/ui/Heading';
import { Box } from '@chakra-ui/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import CheckSocialLoginAction from '../actions/checkSocialLoginAction';
import useUser from '../context/useUser';
import Loading from './partials/Loading';

function Wrapper({ title, indexState, children, breadcrumb }) {
    return (
        <div className="screenview">
            <Head>
                <title>{`${title}  | TakeActive`}</title>
                <meta name="robots" content={indexState} />
            </Head>

            <Header />

            <Bread breadcrumb={breadcrumb} />
            {children}

            <Footer />
            <Consent />
        </div>
    );
}

export default function Layout({
    title,
    children,
    isIndex,
    isRaw = false,
    checkUserAuth = false,
    onlyForGuest = false,
    onlyForAuthenticatedUser = false,
    isExternalLoading = false,
    breadcrumb = null,
}) {
    const router = useRouter();
    let indexState = isIndex === false ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW';
    let { auth, isLoadingAuth, isAuthenticated } = useAuthContext();
    let [user, isLoadingUser, refresh] = useUser();

    useEffect(() => {
        if (
            checkUserAuth &&
            isLoadingAuth === false &&
            isLoadingUser === false
        ) {
            // social login not complete
            // CheckSocialLoginAction(router, isAuthenticated, user)

            // middleware => only for guest
            if (onlyForGuest && isAuthenticated() === true && user) {
                router.push('/dashboard');
            }
            // middleware => only for authenticated user
            if (onlyForAuthenticatedUser && isAuthenticated() === false) {
                router.push('/login?required=true');
            }
        }
    }, [auth, user, isLoadingAuth, isLoadingUser]);

    if (isExternalLoading || (checkUserAuth && isLoadingAuth)) {
        return (
            <Wrapper
                title={title}
                indexState={indexState}
                breadcrumb={breadcrumb}
            >
                <Loading />
            </Wrapper>
        );
    }

    if (isRaw) {
        return (
            <Wrapper
                title={title}
                indexState={indexState}
                breadcrumb={breadcrumb}
            >
                {children}
            </Wrapper>
        );
    }

    return (
        <Wrapper title={title} indexState={indexState} breadcrumb={breadcrumb}>
            <Container>
                <Box my={6} bg="white" rounded="md">
                    <Heading
                        size="lg"
                        px={4}
                        py={5}
                        borderBottom="1px"
                        borderColor="gray.200"
                    >
                        {title}
                    </Heading>

                    <Box px="4" pb="5">
                        {children}
                    </Box>
                </Box>
            </Container>
        </Wrapper>
    );
}
