import React from 'react';
import ActiveLink from '@/components/ActiveLink';
import { useAuthContext } from '@/components/context/authContext';
import { Stack } from '@chakra-ui/react';

const MenuLeft = props => {
    let { isLoadingAuth, isAuthenticated } = useAuthContext();
    let frontpage, search;

    if (isLoadingAuth === true) {
        return null;
    }

    // is user authenticated
    // if (isAuthenticated()) {
    frontpage = (
        <ActiveLink
            href="/dashboard"
            activeClassName="active"
            includePath={true}
        >
            <a className="btn-header">Dashboard</a>
        </ActiveLink>
    );
    search = (
        <ActiveLink href="/suche" activeClassName="active" includePath={false}>
            <a className="btn-header">Suche</a>
        </ActiveLink>
    );
    // } else {
    //     frontpage = (
    //         <>
    //             <ActiveLink href="/" activeClassName="" includePath={false}>
    //                 <a className="btn-header">Startseite</a>
    //             </ActiveLink>
    //             <ActiveLink
    //                 href="/anbieter"
    //                 activeClassName=""
    //                 includePath={false}
    //             >
    //                 <a className="btn-header">Für Anbieter</a>
    //             </ActiveLink>
    //         </>
    //     )
    // }

    return (
        <Stack
            spacing={3}
            align="center"
            justify={['center', 'space-between', 'flex-start']}
            direction={['column', 'row', 'row', 'row']}
            pt={[4, 4, 0, 0]}
        >
            <ActiveLink href="/" activeClassName="" includePath={false}>
                <a className="btn-header">Startseite</a>
            </ActiveLink>
            <ActiveLink href="/anbieter" activeClassName="" includePath={false}>
                <a className="btn-header">Für Anbieter</a>
            </ActiveLink>
            {/* {frontpage} */}
            {/* {search} */}

            <ActiveLink
                href="/kontakt"
                activeClassName="active"
                includePath={false}
            >
                <a className="btn-header">Kontakt</a>
            </ActiveLink>
        </Stack>
    );
};

export default MenuLeft;
