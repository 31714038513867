import * as React from 'react';

function LogoDark(props) {
    return (
        <>
            <style jsx>{`
                .logo {
                    margin-right: 0.5rem;
                    height: 2.4rem;
                }
            `}</style>
            <svg className="logo" viewBox="0 0 517.7 399.13" {...props}>
                <path
                    d="M81.6 285.1a17.2 17.2 0 00-1.2-3.7c-.3-.6-.5-1.2-.8-1.8a15.15 15.15 0 00-1-1.7c-.4-.6-.8-1.1-1.2-1.6s-.9-1-1.3-1.5a6.41 6.41 0 00-1.5-1.3c-.5-.4-1-.8-1.6-1.2a9.36 9.36 0 00-1.7-1 13.15 13.15 0 00-1.8-.8 12.66 12.66 0 00-1.8-.7c-.6-.2-1.3-.3-1.9-.5l-1.9-.3a25.39 25.39 0 00-3.9 0l-1.9.3a17.2 17.2 0 00-3.7 1.2 13.15 13.15 0 00-1.8.8 15.15 15.15 0 00-1.7 1c-.6.4-1.1.8-1.6 1.2s-1 .9-1.5 1.3a9.27 9.27 0 00-1.3 1.5c-.4.5-.8 1-1.2 1.6a9.36 9.36 0 00-1 1.7 13.15 13.15 0 00-.8 1.8 12.66 12.66 0 00-.7 1.8c-.2.6-.3 1.3-.5 1.9a12.5 12.5 0 00-.3 1.9 12.13 12.13 0 00-.1 2 13.34 13.34 0 00.1 2c.1.6.2 1.3.3 1.9a17.2 17.2 0 001.2 3.7c.3.6.5 1.2.8 1.8a15.15 15.15 0 001 1.7c.4.5.8 1.1 1.2 1.6s.9 1 1.3 1.5a16.83 16.83 0 001.5 1.3c.5.4 1 .8 1.6 1.2a9.36 9.36 0 001.7 1 13.15 13.15 0 001.8.8 12.66 12.66 0 001.8.7c.6.2 1.3.3 1.9.5l1.9.3a13.34 13.34 0 002 .1 14.81 14.81 0 002-.1l1.9-.3a17.2 17.2 0 003.7-1.2c.6-.3 1.2-.5 1.8-.8s1.1-.7 1.7-1a9.55 9.55 0 001.6-1.2c.5-.4 1-.9 1.5-1.3a16.83 16.83 0 001.3-1.5c.4-.5.8-1 1.2-1.6a15.15 15.15 0 001-1.7 13.15 13.15 0 00.8-1.8 17.2 17.2 0 001.2-3.7c.1-.6.2-1.3.3-1.9a20.56 20.56 0 000-4 13.65 13.65 0 00-.4-1.9z"
                    fill="#e74e42"
                />
                <path
                    d="M379.2 25.3a16.08 16.08 0 01-13.4 7.1h-13.6v15.4c77.5 12.1 137 79.2 137 160.1 0 89.3-72.7 162-162 162s-162-72.7-162-162c0-80.8 59.5-148 137-160.1V32.4h-13.6a15.91 15.91 0 01-13.4-7.2 191.11 191.11 0 00-100.9 69.6H87.1a20 20 0 100 40h64.3c-2.3 5.4-4.3 11-6.1 16.7H20a20 20 0 000 40h117.3c-.5 5.6-.8 11.3-.8 17.1v3.8H37.3a20 20 0 100 40h104.3c1.3 5.6 2.9 11.1 4.7 16.5h-35.4a20 20 0 100 40h54.2C220.54 398.38 338 426 427.5 370.52a190.6 190.6 0 0090.2-162c0-87.12-58.6-160.62-138.5-183.22z"
                    fill="#e74e42"
                />
                <path
                    d="M197.6 207.9c0 71.4 58.1 129.6 129.6 129.6s129.5-58.2 129.5-129.6S398.6 78.3 327.2 78.3s-129.6 58.1-129.6 129.6zm145.8-83.2v76.4l38.7 38.6a16.16 16.16 0 01-22.39 23.3l-.41-.41-45.4-45.1a9.82 9.82 0 01-2.9-7V124.7a16.2 16.2 0 0132.4 0z"
                    fill="#e74e42"
                />
                <path
                    d="M288.5 32.4h13.6v15.4c-77.5 12.1-137 79.2-137 160.1 0 89.3 72.7 162 162 162s162-72.7 162-162c0-80.8-59.5-148-137-160.1V32.4h13.6a16.08 16.08 0 0013.4-7.1 15.8 15.8 0 002.8-9.1A16.2 16.2 0 00365.7 0h-77.2a16.2 16.2 0 00-16.2 16.2 15.8 15.8 0 002.8 9.1 16.2 16.2 0 0013.4 7.1zm168.2 175.5c0 71.4-58.1 129.6-129.6 129.6s-129.5-58.2-129.5-129.6S255.7 78.3 327.2 78.3s129.5 58.1 129.5 129.6z"
                    fill="#005078"
                />
                <path
                    d="M310.9 124.7v85.8a10.09 10.09 0 002.9 7l45.4 45.2a16.16 16.16 0 1023.21-22.49l-.41-.41-38.7-38.6v-76.4a16.2 16.2 0 10-32.4-.1z"
                    fill="#fff"
                />
            </svg>
        </>
    );
}

export default LogoDark;
