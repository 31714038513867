import { Container } from '@chakra-ui/react';

export default function ContainerComponent({ children, ...props }) {
    return (
        <Container
            className="maximumheight"
            maxW="7xl"
            px={4}
            pb={6}
            {...props}
        >
            {children}
        </Container>
    );
}
