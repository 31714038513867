import { useEffect, useState } from 'react';
import { Box, Button, Container, Link, Flex } from '@chakra-ui/react';
import ActiveLink from '@/components/ActiveLink';
import Cookies from 'js-cookie';

const Consent = props => {
    // prepare
    const [showConsent, setShowConsent] = useState(false);

    useEffect(() => {
        // check
        if (Cookies.get('cookieconsent') !== 'yes') {
            setShowConsent(true);
        }
    }, []);

    return (
        <Box
            display={showConsent === false ? 'none' : 'block'}
            id="cookieconsent"
            bg="gray.800"
            pos="fixed"
            w="100%"
            zIndex={50}
            bottom={0}
        >
            <Container maxW="7xl" p="6" color="white">
                <Flex
                    align="center"
                    flexDirection={['column', 'column', 'column', 'row']}
                >
                    <Box>
                        <strong>Diese Webseite verwendet Cookies</strong>
                        <br />
                        Cookies helfen uns bei der Bereitstellung unserer
                        Dienste. Durch die Nutzung unseres Angebots erklären Sie
                        sich damit einverstanden, dass wir Cookies setzen.
                        <ActiveLink
                            href="/datenschutz"
                            activeClassName="active"
                        >
                            <Link
                                px="2"
                                color="white"
                                textDecoration="underline"
                            >
                                Mehr ...
                            </Link>
                        </ActiveLink>
                    </Box>
                    <Button
                        p={6}
                        ml={[0, 0, 0, 4]}
                        mt={[4, 4, 4, 0]}
                        colorScheme="green"
                        onClick={() => {
                            Cookies.set('cookieconsent', 'yes', {
                                expires: 365,
                            });
                            setShowConsent(false);
                        }}
                    >
                        Einverstanden
                    </Button>
                </Flex>
            </Container>
        </Box>
    );
};

export default Consent;
