import React from 'react';
import Link from 'next/link';
import {
    Box,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
// import dayjs from 'dayjs'

function item(title, href = '', isLast = false) {
    if (isLast) {
        return lastItem(title);
    }

    return (
        <BreadcrumbItem key={title}>
            <BreadcrumbLink as={Link} href={href}>
                {title}
            </BreadcrumbLink>
        </BreadcrumbItem>
    );
}

function lastItem(title) {
    return (
        <BreadcrumbItem key={title} isCurrentPage={true} isLastChild={true}>
            <BreadcrumbLink
                color="gray.500"
                fontWeight="semibold"
                _hover={{ textDecoration: 'none', cursor: 'default' }}
            >
                {title}
            </BreadcrumbLink>
        </BreadcrumbItem>
    );
}

function frontpage(isLast = false) {
    return item('Startseite', '/', isLast);
}

function dashboard(isLast = false) {
    return item('Dashboard', '/dashboard', isLast);
}

function search(isLast = false) {
    return item('Suche', '/suche', isLast);
}

function contact(isLast = false) {
    return item('Kontakt', '/kontakt', isLast);
}

function settings(isLast = false) {
    return item('Einstellungen', '/einstellungen', isLast);
}

function administation(isLast = false, company) {
    if (company !== null) {
        return item('Administration', '/' + company?.id + '/admin', isLast);
    }

    return item('Administration', '/admin', isLast);
}

function company(isLast = false, company) {
    if (company === null || company === undefined) {
        return item('Loading...', '#', isLast);
    }
    return item(company.name, '/' + company.id, isLast);
}

function appointment(isLast = false, { company, appointment }) {
    if (appointment !== null) {
        // let d = dayjs(appointment.appointment).format('HH:mm DD.MM.YYYY')
        return item(
            appointment.name,
            '/' + company.id + '/' + appointment.id,
            isLast,
        );
    }
}

function audithistory(isLast = false, company) {
    return item(
        'Benutzeraktivitäten',
        '/' + company?.id + '/admin/stats_audithistory',
        isLast,
    );
}

function audithistory_user(isLast = false, name) {
    return item(name, '#', true);
}

function appointmentCreate() {
    return lastItem('Termin erstellen');
}

const Bread = props => {
    let items = null;

    if (props.breadcrumb) {
        let keys = Object.keys(props.breadcrumb);
        let lastKeyName = keys[Object.keys(keys).length - 1];

        items = Object.keys(props.breadcrumb).map(key => {
            let item = props.breadcrumb[key];
            let isLast = lastKeyName === key;

            if (eval('typeof ' + key) === 'function') {
                return eval(key)(isLast, item);
            }
        });
    }

    if (items === null) {
        return null;
    }

    return (
        <Box bg="gray.300" shadow="sm">
            <Container maxW="7xl" px={4} pb="2">
                <Breadcrumb
                    // mt="1"
                    // mb="6"
                    fontWeight="medium"
                    fontSize="sm"
                    spacing="6px"
                    separator={
                        <Icon
                            className="fill-current"
                            path={mdiChevronRight}
                            size={0.9}
                            color="#555"
                        />
                    }
                >
                    {items}
                </Breadcrumb>
            </Container>
        </Box>
    );
};

export default Bread;
