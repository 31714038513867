import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Link from 'next/link';
import React, { Children } from 'react';

function IsActive(props) {
    const router = useRouter();

    // for realative path example: /blog/hello-world => /blog = true
    if (props.includePath && router.pathname.includes(props.href)) {
        return true;
    }

    // for exact path example: /blog => /blog = true
    return router.asPath === props.href;
}

const ActiveLink = ({ children, activeClassName, ...props }) => {
    const child = Children.only(children);
    const childClassName = child.props.className || '';

    const className = IsActive(props)
        ? `${childClassName} ${props.activeClass}`
        : childClassName;

    return (
        <Link {...props}>
            {React.cloneElement(child, {
                className: className || null,
            })}
        </Link>
    );
};

ActiveLink.propTypes = {
    href: PropTypes.string.isRequired,
    activeClass: PropTypes.string.isRequired,
    includePath: PropTypes.bool,
};

ActiveLink.defaultProps = {
    activeClass: 'active',
    includePath: true,
};

export default ActiveLink;
